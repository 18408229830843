import React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { EditorialContentListProps } from '../components/molecules/editorial-content-list';
import { TitleCtaProps } from '../components/molecules/title-cta';
import { TagList } from '../components/molecules/tag-list';
import { Image, ImageProps } from '../components/atoms/image';
import { Heading } from '../components/atoms/heading';
import { RelatedContent } from '../components/organisms/related-content';
import { HeaderSection } from '../components/molecules/header-section';
import { useBreakpoints } from '../hooks/useBreakpoints';
import { EditorialSection, mapEditorialSectionToComponent } from '../components/organisms/editorial-section';

export type ArticleListSection = {
  title?: TitleCtaProps;
  content?: EditorialContentListProps;
};

export type ArticleProps = {
  header?: {
    title: string;
    tags: string[];
    image?: ImageProps;
  };
  sections: EditorialSection[];
  relatedContent?: ArticleListSection;
};

const ArticleHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
`;

const ArticleBody = styled.div`
  margin: 0 20px;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin: 0 auto;
  }
`;

export const ArticleTemplate: React.FC<ArticleProps> = props => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoints();

  return (
    <>
      {props.header && (
        <ArticleHeader>
          <HeaderSection>
            <Heading level="h1" text={props.header?.title} noMargin />
            <TagList tags={props.header?.tags} />
          </HeaderSection>
          <div>
            <Image {...props.header?.image} />
          </div>
        </ArticleHeader>
      )}

      <ArticleBody>
        {props.sections?.map((section, index) => {
          const id = `${section.type}-${index}`;
          const nextSection = props.sections[index + 1]?.type;
          return mapEditorialSectionToComponent(section, id, !isDesktop, theme, nextSection);
        })}
      </ArticleBody>
      {props?.relatedContent?.title && props?.relatedContent?.content && (
        <RelatedContent type="article" {...props.relatedContent} />
      )}
    </>
  );
};
