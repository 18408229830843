import React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import { graphql } from 'gatsby';
import { ArticleTemplate, ArticleProps } from '../templates/article.template';

import SEO from '../components/seo';

import {
  mapFragmentToArticleHeaderProps,
  mapFragmentToArticleRelatedContentProps,
} from '../data-mappers/article.mapper';
import { parseLocale } from '../locale';
import { ArticleDataQuery } from '../../graphql-types';
import { safeApply } from '../data-mappers/utils';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';
import { mapFragmentToEditorialSectionsProps } from '../data-mappers/editorial.mapper';
import { LocalizedSlug, useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

type ArticlePageProps = {
  data: ArticleDataQuery;
};

const ArticlePage: React.FC<ArticlePageProps> = ({ data }) => {
  const article = data.cms.article;

  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  const articleProps: ArticleProps = {
    header: safeApply(value => mapFragmentToArticleHeaderProps(value, locale), article),
    sections: mapFragmentToEditorialSectionsProps(article, locale),
    relatedContent: mapFragmentToArticleRelatedContentProps(article, locale),
  };

  useBreadcrumbs({
    pageTitle: articleProps?.header?.title,
    contentCategory: 'Newsroom',
    contentCategoryUrl: '/newsroom',
  });

  const generateLocalizedSlugs = (data: ArticleDataQuery): LocalizedSlug[] => {
    const localizedSlugs: LocalizedSlug[] = [];
    const article = data?.cms?.article;
    article?.localizations?.forEach((localization: any) => {
      if (localization) {
        return localizedSlugs.push({
          locale: localization.locale,
          slug: localization.locale === 'en' ? `news/${localization.slug}` : `articles/${localization.slug}`,
        });
      }
    });
    return localizedSlugs;
  };

  useLocalizedSlugs({
    slugs: article?.localizations
      ? generateLocalizedSlugs(data)
      : [
          { locale: 'en', slug: 'newsroom' },
          { locale: 'fr', slug: 'articles' },
        ],
  });

  const seoProps = {
    title: articleProps?.header?.title ?? '',
    description: article?.metas?.description ?? '',
    lang: locale,
  };

  return (
    <>
      <SEO {...seoProps} />
      <ArticleTemplate {...articleProps} />
    </>
  );
};

export default ArticlePage;

export const query = graphql`
  query ArticleData($articleId: ID!) {
    cms {
      article(id: $articleId) {
        ...Article
        metas {
          id
          description
        }
        image {
          ...CommonMediaField
          staticFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        image_mobile {
          ...CommonMediaField
          staticFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        sections {
          __typename
          ...TestimonialsWithImages
          ...AlignedMarkdown
          ...KeyFiguresWithImage
          ...MultipleKpiCards
          ...TextOnBackgroundImage
          ...SimpleImageCard
          ...HomepageTextContentWithMedia
        }
        related_content {
          ...RelatedContentHeader
        }
        articles {
          ...ArticleLink
        }
      }
    }
  }
`;

export const articleFragment = graphql`
  fragment Article on cms_Article {
    id
    title
    excerpt
    slug
    tags {
      ...Tag
    }
    localizations {
      ...ArticleLocalization
    }
  }

  fragment ArticleLocalization on cms_Article {
    id
    slug
    locale
  }

  fragment ArticleThumbnail on cms_UploadFile {
    id
    name
    caption
    alternativeText
    staticFile {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }

  fragment ArticleWithThumbnail on cms_Article {
    ...Article
    thumbnail {
      ...ArticleThumbnail
    }
    thumbnail_mobile {
      ...ArticleThumbnail
    }
  }
`;
