import React from 'react';
import styled from '@emotion/styled';
import { CTA, CtaProps } from './cta';
import { Heading } from '../atoms/heading';

export type TitleCtaProps = {
  title: string;
  body?: string;
  cta?: CtaProps;
};

const TitleRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: row;
    text-align: left;
    margin-bottom: 0;
  }
`;

const HeadingWrapper = styled.div`
  margin-right: 0;
  margin-bottom: 40px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-right: 60px;
    margin-bottom: 0;
  }
`;

const Text = styled.p`
  margin: ${({ theme }) => theme.spacing.unit * 2}px 0;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    text-align: left;
  }
`;

export const TitleCta: React.FC<TitleCtaProps> = props => {
  return (
    <div>
      <TitleRow>
        <HeadingWrapper>
          <Heading level="h2" noMargin text={props.title} />
        </HeadingWrapper>
        {props.cta && <CTA {...props.cta} icon="right-arrow" size="medium" />}
      </TitleRow>

      {props.body ? <Text>{props.body}</Text> : null}
    </div>
  );
};
