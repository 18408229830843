import { ArticleProps } from '../templates/article.template';
import { ArticleDataQuery, RelatedContentHeaderFragment } from '../../graphql-types';
import { AvailableLocales } from '../locale';
import { mapFragmentToImageProps } from './image.mapper';
import {
  mapFragmentToEditorialContentThumbmailProps,
  mapFragmentToTagList,
  mapFragmentToTitleCtaProps,
} from './homepage.mapper';

import { EditorialContentListProps } from '../components/molecules/editorial-content-list';
import { safeApply, safeMap } from './utils';

export const mapFragmentToArticleHeaderProps = (
  article: NonNullable<ArticleDataQuery['cms']['article']>,
  locale: AvailableLocales
): ArticleProps['header'] => {
  return {
    title: article.title ?? '',
    tags: mapFragmentToTagList(article.tags),
    image: mapFragmentToImageProps(article.image, locale),
  };
};

export function mapFragmentToArticleListProps(
  sections: Array<RelatedContentHeaderFragment | null | undefined>,
  locale: AvailableLocales
): EditorialContentListProps {
  return {
    thumbnails: safeMap(fragment => mapFragmentToEditorialContentThumbmailProps(fragment, 'news', locale), sections),
    showContentTags: true,
  };
}

export const mapFragmentToArticleRelatedContentProps = (
  article: ArticleDataQuery['cms']['article'],
  locale: AvailableLocales
): ArticleProps['relatedContent'] => {
  return {
    title: safeApply(value => mapFragmentToTitleCtaProps(value), article?.related_content),
    content: safeApply(value => mapFragmentToArticleListProps(value, locale), article?.articles),
  };
};
