import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import { EditorialContentList, EditorialContentListProps } from '../molecules/editorial-content-list';
import { TitleCta, TitleCtaProps } from '../molecules/title-cta';
import { Heading } from '../atoms/heading';
import { Section } from '../molecules/section';
import { useBreakpoints } from '../../hooks/useBreakpoints';

type RelatedContentProps = {
  type: 'program' | 'article';
  title?: TitleCtaProps;
  content?: EditorialContentListProps;
};

const RelatedContentWrapper = styled(Section)<{ type: 'program' | 'article' }>`
  border-top: ${({ type }) => (type === 'program' ? '1px solid black' : 'none')};
  background-color: ${({ theme, type }) =>
    type === 'article' ? theme.color.palette['gray-lighten-3'] : 'transparent'};
  margin-top: ${({ theme }) => theme.spacing.sectionGap};
  margin-bottom: ${({ theme, type }) => (type === 'article' ? `-${theme.spacing.sectionGap}` : '0')};

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    padding: 80px 80px 0;
    margin-top: 0;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing.sectionGap};

  @media (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-left: 20px;
    margin-right: 20px;
  }

  h1 {
    font-weight: 100;
    text-align: center;
  }
`;

export const RelatedContent: React.FC<RelatedContentProps> = props => {
  const { isDesktop } = useBreakpoints();

  return (
    <RelatedContentWrapper type={props.type}>
      {isDesktop ? (
        props.title && <TitleCta {...props.title} />
      ) : (
        <HeadingWrapper>
          <Heading level="h1" noMargin>
            {props.title && <TitleCta {...props.title} />}
          </Heading>
        </HeadingWrapper>
      )}
      {props.content && <EditorialContentList {...props.content} />}
    </RelatedContentWrapper>
  );
};

export const relatedContentHeaderFragment = graphql`
  fragment RelatedContentHeader on cms_ComponentComponentRelatedContentHeader {
    title
    text
    link {
      ...Link
    }
  }
`;

export const relatedContentFragment = graphql`
  fragment RelatedContent on cms_ComponentAtomRelatedContentCard {
    article {
      ...ArticleLink
    }
    program {
      ...ProgramLink
    }
    internal_link {
      Slug
    }
    instagram_text
    instagram_story
    link_title
    illustration {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(width: 275, height: 275, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
    open_in_new_tab
    show_instagram
    show_facebook
    show_twitter
  }
`;
